import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  serverUrl: string = environment.baseUrl;
  constructor(private http: HttpClient) {}

  // Project Dashboard APIs
  getAllProjectsReport() {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/new/dashboard/report/project?name=all_projects_report`
    );
  }

  getProjectWiseReport(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/new/dashboard/report/district?project_id=${id}&name=district_report`
    );
  }

  // Cala Dashboard APIs
  getAllCalaReports() {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/all/cala/progress/report?name=cala_report`
    );
  }

  getCalaWiseProjectsReport(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/single/cala/progress/report/${id}?name=project_report`
    );
  }

  getProjectWiseVillagesReport(pid, cid) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/single/project/village/report/${pid}?cala_id=${cid}&name=village_report`
    );
  }

  // Report Download Status API
  checkDownloadStatus(id) {
    return this.http.get<boolean>(
      `https://${this.serverUrl}/api/project/check/report/status?report_id=${id}`
    );
  }

  getReportsOnDownload() {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/check/report/status`
    );
  }

  // Reports Tab APIs
  getAllProjectsList() {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/list/all/report`
    );
  }

  // master report download

  getAllMasterReport(){
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/master-report?name=master_report`
    );
  }

  //project monthly reports

  getProjectMonthlyReport(id){
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/project-monthly-report?project_id=${id}`
    );
  }

  // Cala Monthly Report

  getCalaMonthlyReport(id){
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/cala-report-full?cala_id=${id}`
    );
  }

  getCollectorMonthlyReport(id){
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/dc-report-full?dc_id=${id}`
    );
  }

  getCommissionerMonthlyReport(id){
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/comissioner-report-full?comissioner_id=${id}`
    );
  }

  getCriticalMonthlyReport(id){
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/critical-report?ro_id=${id}`
    );
  }
}
