import {
  Component,
  OnInit,
  OnDestroy,
  AfterContentInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import * as Highcharts from "highcharts/highstock";
import HC_exporting from "highcharts/modules/exporting";

import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import { ReportsService } from "../../services/reports.service";
import { DashboardService } from "../../services/dashboard.service";
import { CalaDetailModel } from "../../shared/dashboradData.model";

declare var require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "app-collector-dashboard",
  templateUrl: "./collector-dashboard.component.html",
  styleUrls: ["./collector-dashboard.component.scss"],
})
export class CollectorDashboardComponent
  implements OnInit, AfterViewInit, AfterContentInit, OnDestroy
{
  displayedColumns: string[] = [
    "CALA Name",
    "Total land to be aquired",
    "Start Date",
    "3a status",
    "JMS request status",
    "JMS",
    "3A",
    "3C",
    "3D",
    "3G",
    "3H",
    "3H funds distributed",
    "Possession",
    "Mutation",
    "Govt. Land To Be Aquired",
    "Govt. Land Possession",
    "Govt. Land Mutation",
    "Private Land To Be acquired",
    "Total Private Land acquired",
    "Total Private fund disbursed",
    "Funds Diposition Under PN",
    "Funds Distribution Under PN",
  ];
  public dataSource = new MatTableDataSource<any>();

  isDownloading: boolean = false;
  isDownloaded: boolean = false;

  id: string = "128";
  projectName: string = "";
  toBeCompleted: any;
  completedTillNow: any;
  projectsList: any;
  inPercentage: any;
  overviewData: any;
  graphData: object;
  array=[];
  day: number = 5;
  colors = ["#FD6452", "#8FCF52"];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("convertToPDF") makePDF: ElementRef;
  @ViewChild("overviewTable") overviewTable: ElementRef;
  constructor(
    private router: Router,
    private reportsService: ReportsService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.id = params.get("id");
      this.projectName = params.get("name");
    });
    this.dashboardService
      .getCollectorDashboardData(this.id)
      .subscribe((res) => {
        // console.log(res);
        
        this.projectName=res.name
        this.overviewData = res.overview;
        this.toBeCompleted = res.overview.to_be_completed;
        this.completedTillNow = res.overview.total_completed_till_now;
        this.inPercentage = res.percentage;
        this.projectsList = res.details;
        this.array = res.details;
        this.array.sort(function (a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1
          }
          return 0;
        })
        this.dataSource.data = res.details;
        

        //////////////////// High Charts //////////////////////////
        const options: any = {
          chart: {
            type: "bar",
          },
          title: {
            text: "Total land aquisition in hactare",
            style: {
              // color: '#FFF',
              font: '14px Montserrat,sans-serif;',
              fontWeight:600
            }
          },
          subtitle: {
            text: "",
          },
          accessibility: {
            announceNewData: {
              enabled: true,
            },
          },
          xAxis: {
            type: "category",
            labels: {
              style: {
                // color: '#FFF',
                font: '14px Montserrat,sans-serif;',
                fontWeight:600
              }
            },
          },
          yAxis: {
            title: {
              text: "In hactares",
              labels: {
                style: {
                  // color: '#FFF',
                  font: '14px Montserrat,sans-serif;',
                  fontWeight:600
                }
              },
            },
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            series: {
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                formatter: function () {
                  const y = this.y;
                  const total = res.overview.total_land_aquire;
                  const pct = (y / total) * 100;
                  return `${pct.toFixed(2)}%`;
                },
              },
            },
          },

          tooltip: {
            headerFormat:
              '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> of <b>${res.overview.total_land_aquire}</b><br/>`,
          },

          series: [
            {
              name: "Aquisition Step",
              colorByPoint: true,
              data: [
                {
                  name: "JMS",
                  y: res.overview.total_completed_till_now.jms,
                },
                {
                  name: "3(A)",
                  y: res.overview.total_completed_till_now["3A"],
                },
                {
                  name: "3(C)",
                  y: res.overview.total_completed_till_now["3C"],
                },
                {
                  name: "3(D)",
                  y: res.overview.total_completed_till_now["3D"],
                },
                {
                  name: "3(G)",
                  y: res.overview.total_completed_till_now["3G"],
                },
                {
                  name: "3(H)",
                  y: res.overview.total_completed_till_now["3H_area"],
                },
                {
                  name: "Possession",
                  y: res.overview.total_completed_till_now.possession,
                },
                {
                  name: "Mutation",
                  y: res.overview.total_completed_till_now.mutation,
                },
                {
                  name: "Govt. Land Possession",
                  y: res.overview.total_completed_till_now.govt_possession,
                },
                {
                  name: "Govt. Land Mutation",
                  y: res.overview.total_completed_till_now.govt_mutation,
                },
              ],
            },
          ],
        };
        HC_exporting(Highcharts);
        Highcharts.chart("container", options);
      });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngAfterContentInit() {}

  public doFilter = (value: string) => {
    this.dataSource.filter = value.toLocaleLowerCase();
  };

  projectDetails(id, name) {
    // console.log(id, name);
    localStorage.setItem("PROJECT_ID", id);
    localStorage.setItem("PROJECT_NAME", name);
    this.router.navigate(["/project-info"]);
  }

  logData(cala) {
    this.router.navigate(["/cala", cala.id]);
  }

  downloadReport() {
    this.reportsService.getAllProjectsReport().subscribe((res) => {
      this.isDownloading = true;
      // console.log(res);
      this.reportsService.checkDownloadStatus(res.id).subscribe((res) => {
        this.isDownloading = false;
        this.isDownloaded = res;
        // console.log(res);
      });
    });
  }

  downloadPageAsPDF() {
    const pdfTable = this.makePDF.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = {
      pageSize: "A3",
      content: html,
      pageOrientation: "landscape",
      pageMargins: [10, 60, 10, 60],
    };
    pdfMake.createPdf(documentDefinition).download();
  }

  // downlaodOverviewTableAsPDF() {
  //   const pdfTable = this.overviewTable.nativeElement;
  //   var html = htmlToPdfmake(pdfTable.innerHTML);
  //   const documentDefinition = {
  //     pageSize: "A2",
  //     content: html,
  //     pageOrientation: "portrait",
  //     pageMargins: [10, 60, 10, 60],
  //   };
  //   pdfMake.createPdf(documentDefinition).download();
  // }

  ngOnDestroy() {}
}
