import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";

export interface NewDateModel {
  project: number;
  cala: number;
  land: number;
  date: string;
}

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  serverUrl: string = environment.baseUrl;
  projectName: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor(private http: HttpClient) { }

  getAllProjects(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/all/projects/list?agency=${id}`
    );
  }

  // Get project for filter

  getAllFilterProjects(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/filter/project-attributes?agency=${id}`
    );
  }

  // get filter district list

  getAllFilterDistrict(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/filter/all-district?agency=${id}`
    );
  }
  getmoreFilterProject(url) {
    return this.http.get<any>(
      url
    );
  }


  getAllCalaList(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/all/cala/list?agency=${id}`
    );
  }

  getDashboardData(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/main/dashboard?agency=${id}`
    );
  }

  // Project Report Dashboard
  getSingleProjectDetails(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/project/details/${id}`
    );
  }
  getSingleProjectDetailsReport(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/dashboard/project-report/${id}`
    );
  }

  // Timeline Graph
  getGraphData(pid) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/village/progress/graph/${pid}`
    );
  }

  // CALA DASHBOARD
  getCalaDashboardData(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/cala/dashboard/${id}`
    );
  }

  getCalaDashboardDataReport(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/dashboard/cala-report/${id}`
      
    );
  }

  getCollectorDashboardData(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/collector-dashboard/${id}`
    );
  }

  getCollectorDashboardDataReport(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/dashboard/dc-report/${id}`
    );
  }

  getCommissionerDashboardData(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/comissioner/dashboard/${id}`
    );
  }

  getCommissionerDashboardDataReport(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/dashboard/comissioner-report/${id}`
    );
  }

  getLastFourYearsData(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/cala/last/4years/graph/${id}`
    );
  }

  /////////// Critical Analysis Reports ////////////////////
  getROList(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/regional-officer-list?agency=${id}`
    );
  }

  getAllProjectListbyRo(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/projects-list-by-ro/${id}`
    );
  }

  // getAllCriticalReports(id, pids) {
  //   return this.http.get<any>(
  //     `https://${this.serverUrl}/api/project/regional-officer/critical-report/${id}?id=${pids}`
  //   );
  // }

  getDistrictCollectorList(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/all/dc/list?agency=${id}`
    );
  }

  getCommissionerList(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/comissioner-list?agency=${id}`
    );
  }

  getAllCriticalReports(id, pids) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/regional-officer/critical-report/${id}?id=${pids}`
    );
  }

  getPreviousDates(pid, cid, aquisitionStage) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/get-dates?project_id=${pid}&cala_id=${cid}&process_name=${aquisitionStage}`
    );
  }

  ////////// Post new Completion date ////////////////
  postNew3ADate(data) {
    return this.http.post<NewDateModel>(
      `https://${this.serverUrl}/api/project/critical-report/3A`,
      data
    );
  }
  postNew3DDate(data) {
    return this.http.post<NewDateModel>(
      `https://${this.serverUrl}/api/project/critical-report/3D`,
      data
    );
  }
  postNew3GDate(data) {
    return this.http.post<NewDateModel>(
      `https://${this.serverUrl}/api/project/critical-report/3G`,
      data
    );
  }
  postNew3HDate(data) {
    return this.http.post<any>(
      `https://${this.serverUrl}/api/project/critical-report/3H`,
      data
    );
  }

  //  Principle secretary data
  getSecreataryDashboardData(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/principal-dashboard/${id}`
    );
  }

  getSecreataryDashboardDataReport(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/dashboard/principal-report/${id}`
    );
  }

  // download critical report

  downloadAllCriticalReports(id, pids) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/ro-pdf-report/${id}?id=${pids}`
    );
  }

  projectCount(agency,p){
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/clickable-projects?agency=${agency}&page=${p}`
    );
  }

  calacount(agency){
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/clickable-cala?agency=${agency}`
    );
  }
  calamorecount(url){
    return this.http.get<any>(
      url
    );
  }

  districtCount(agency,p){
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/clickable-district?agency=${agency}&page=${p}`
    );
  }

  villageCount(agency,q){
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/clickable-village?agency=${agency}&page=${q}`
    );
  }

  monitoringProjectdata(agency,attributes,m){
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/clickable-attributes?agency=${agency}&attribute=${attributes}&name=monitoring&page=${m}`
    );
  }

  priorityProjectdata(agency,attributes,q){
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/clickable-attributes?agency=${agency}&attribute=${attributes}&name=priority&page=${q}`
    );
  }

  stageProjectdata(agency,attributes,r){
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/clickable-attributes?agency=${agency}&attribute=${attributes}&name=stage&page=${r}`
    );
  }

  // activity Logs

  activityLog(p){
    return this.http.get<any>(
      `https://${this.serverUrl}/api/activity/logs-list?page=${p}`
    );
  }

  //  Pagination activity log

  // pageActivityLog(url){
  //   return this.http.get<any>(
  //     url
  //   );
  // }


}
