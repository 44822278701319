import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class NewProgressService {

    serverUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) { }


    addNewProgressData(data) {
        return this.http.post<any>(`https://${this.serverUrl}/api/project/add/new-progress`, data, {responseType:"json",observe: 'response'});
    }

}