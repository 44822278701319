import {
  Component,
  OnInit,
  OnDestroy,
  AfterContentInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import * as Highcharts from "highcharts/highstock";
import HC_exporting from "highcharts/modules/exporting";

import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import { ReportsService } from "../../services/reports.service";
import { DashboardService } from "../../services/dashboard.service";
import { ProjectDetailModel } from "../../shared/dashboradData.model";

declare var require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);

@Component({
  selector: "app-project-dashboard",
  templateUrl: "./project-dashboard.component.html",
  styleUrls: ["./project-dashboard.component.scss"],
})
export class ProjectComponent
  implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
  displayedColumns: string[] = [
    "Village Name",
    "District Name",
    "Total land to be aquired",
    "Start Date",
    "3a status",
    "JMS request status",
    "JMS",
    "3A",
    "3C",
    "3D",
    "3G",
    "3H",
    "3H funds distributed",
    "Possession",
    "Mutation",
    "Govt. land to be aquired",
    "Govt. Land Possession",
    "Govt. Land Mutation",
    "Total Area to be acquired by PN",
    "Area Actually acquired by PN",
    "Total Private fund disbursed",
    "Funds Diposition Under PN",
    "Funds Distribution Under PN",
  ];
  public dataSource = new MatTableDataSource<any>();

  isDownloading: boolean = false;
  isDownloaded: boolean = false;

  id: string = "";
  projectName: string = "";
  toBeCompleted: any;
  completedTillNow: any;
  inPercentage: any;
  overviewData: any;
  graphData: object;
  totalProjects: number;
  concernedPersons: any;
  pnDetails
  araay = [];
  dateTobecompleted;
  jmsvalue;
  avalue;
  newProgressDetails

  day: number = 5;
  colors = ["#E59866", "#BFF68A"];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private router: Router,
    private reportsService: ReportsService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.id = params.get("id");
      // this.projectName = params.get("name");
    });
    this.dashboardService.getSingleProjectDetails(this.id).subscribe((res) => {
      console.log(res);
      this.pnDetails=res.PN_details
      this.newProgressDetails= res.new_progress_details
      this.projectName = res.name
      this.concernedPersons = res.officers_list;
      this.overviewData = res.overview;
      this.toBeCompleted = res.overview.to_be_completed;
      this.completedTillNow = res.overview.total_completed_till_now;
      this.inPercentage = res.percentage;
      this.jmsvalue = res.percentage.percentage_jms_request;
      this.avalue = res.percentage.percentage_3a;
      this.totalProjects = res.details.length;
      this.dataSource.data = res.details as ProjectDetailModel[];
      this.araay = res.details;
      this.araay.sort(function (a, b) {
        if (a.village_name.toLowerCase() < b.village_name.toLowerCase()) {
          return -1
        }
        if (a.village_name.toLowerCase() > b.village_name.toLowerCase()) {
          return 1
        }
        return 0;
      })
      // console.log(this.araay, "sorting a/c alphabetically")
    });


    setTimeout(() => {
      this.dashboardService.getGraphData(this.id).subscribe((res) => {
        console.log(res, "graph data");
        this.dateTobecompleted = res

        // this.dateFormatter(res.start_date);
        //////////////////// High Charts //////////////////////////
        const options: any = {
          chart: {
            type: "bar",
          },
          title: {
            text: `Total Area To Be Acquired: ${res.total_land_aquare} Ha`,
            style: {
              // color: "#fff",
              font: '16px Montserrat,sans-serif;',
              fontWeight:700
            }
          },
          subtitle: {
            text: `Project Start Date: ${res.start_date
              .split("-")
              .reverse()
              .join("/")}`,
              style: {
                // color: "#fff",
                font: '12px Montserrat,sans-serif;',
                fontWeight:700
              }
          },
          xAxis: {
            categories: [
              "3a",
              "JMS Request",
              "JMS",
              "3(A)",
              "3(C)",
              "3(D)",
              "3(G)",
              "3(H)",
              "Possession",
              "Mutation",
            ],
            labels: {
              style: {
                // color: '#FFF',
                font: '13px Montserrat,sans-serif;',
                fontWeight:600
              }
            },
          },
          yAxis: {
            min: this.expectedDateFormatter(res.start_date),
            type: "datetime",
            labels: {
              formatter: function () {
                return Highcharts.dateFormat("%d/%m/%y", this.value);
              },
              style: {
                // color: '#FFF',
                font: '13px Montserrat,sans-serif;',
                fontWeight:600
              }
            },
            title: {
              text: "Dates",
            },
          },
          tooltip: {
            enabled: false,
            formatter: function () {
              const days = this.y / (1000 * 3600 * 24);
              return `${this.series.name}: ${days} days`;
            },
          },

          plotOptions: {
            borderWidth: 0,
            series: {
              pointWidth: 15,
              stacking: "true",
              dataLabels: {
                enabled: true,
                // formatter: function () {
                //   var y = this.series.data[4].name

                //   const total = res.total_land_aquare;
                //   const pct = (y / total) * 100;
                //   console.log(this.series.data.length,)
                //   return `${pct.toFixed(2)}%`;
                // },
              }
            },
          },
          bar: {

          },
          colors: this.colors,
          series: [

            {
              name: "Delayed",
              dataLabels: [{
                align: 'right',
                x: +60,
                format: '{point.name}'
              }],
              data: [
                {
                  name: this.avalue, y: this.runningDateFormatter(
                    res.date_of_3a_expected,
                    res.date_of_3a_completed || new Date()
                  )
                },
                {
                  name: this.jmsvalue, y: this.runningDateFormatter(
                    res.jms_request_expected,
                    res.jms_request_completed || new Date()
                  )
                },
                {
                  name: ((res.total_jms / res.total_land_aquare) * 100).toFixed(2) + "%", y: this.runningDateFormatter(
                    res.Jms_expected,
                    res.Jms_completed || new Date()
                  )
                },
                {
                  name: ((res.total_3A / res.total_land_aquare) * 100).toFixed(2) + '%', y: this.runningDateFormatter(
                    res.dates_of_3A_expected,
                    res.dates_of_3A_completed || new Date()
                  )
                },
                {
                  name: ((res.total_3C / res.total_land_aquare) * 100).toFixed(2) + '%', y: this.runningDateFormatter(
                    res.dates_of_3C_expected,
                    res.dates_of_3C_completed || new Date()
                  )
                },
                {
                  name: ((res.total_3D / res.total_land_aquare) * 100).toFixed(2) + "%", y: this.runningDateFormatter(
                    res.dates_of_3D_expected,
                    res.dates_of_3D_completed || new Date()
                  )
                },
                {
                  name: ((res.total_3G / res.total_land_aquare) * 100).toFixed(2) + "%", y: this.runningDateFormatter(
                    res.dates_of_3G_expected,
                    res.dates_of_3G_completed || new Date()
                  )
                },
                {
                  name: ((res.total_3H_area / res.total_land_aquare) * 100).toFixed(2) + "%", y: this.runningDateFormatter(
                    res.dates_of_3H_expected,
                    res.dates_of_3H_completed || new Date()
                  )
                },
                {
                  name: ((res.total_possession / res.total_land_aquare) * 100).toFixed(2) + "%", y: this.runningDateFormatter(
                    res.dates_possesion_expected,
                    res.dates_possesion_completed || new Date()
                  )
                },
                {
                  name: ((res.total_mutation / res.total_land_aquare) * 100).toFixed(2) + "%", y: this.runningDateFormatter(
                    res.dates_of_mutation_expected,
                    res.dates_of_mutation_completed || new Date()
                  )
                },
              ],
            },
            {
              name: "Scheduled time",
              dataLabels: [{
                align: 'right',
                x: +10,
                format: ''
              }],
              data: [
                {
                  name: '', y: this.dateComparator(
                    res.start_date,
                    res["date_of_3a_expected"],
                    res.date_of_3a_completed || new Date()
                  )
                },

                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["jms_request_expected"],
                    res.jms_request_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["Jms_expected"],
                    res.Jms_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["dates_of_3A_expected"],
                    res.dates_of_3A_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["dates_of_3C_expected"],
                    res.dates_of_3C_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["dates_of_3D_expected"],
                    res.dates_of_3D_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["dates_of_3G_expected"],
                    res.dates_of_3G_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["dates_of_3H_expected"],
                    res.dates_of_3H_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["dates_possesion_expected"],
                    res.dates_possesion_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["dates_of_mutation_expected"],
                    res.dates_of_mutation_completed || new Date()
                  )
                },
              ],
            },
          ],
        };
        HC_exporting(Highcharts);
        Highcharts.chart("container", options);
      });
    }, 900);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  dateFormatter(date) {
    const dayConvertor = 1000 * 3600 * 24;
    const jsDate = Date.UTC(1970, 1, 1);
    const dateObject = new Date(date);
    const year = dateObject.getUTCFullYear();
    const month = dateObject.getUTCMonth();
    const day = dateObject.getUTCDate();
    const dateDifference = Date.UTC(year, month, day) - jsDate;
    const days = dateDifference / dayConvertor;
    // console.log(days);

    return dateDifference;
  }

  expectedDateFormatter(date) {
    const initialState = Date.UTC(1970, 0, 1);
    const dateObject = new Date(date);
    const year = dateObject.getUTCFullYear();
    const month = dateObject.getUTCMonth();
    const day = dateObject.getUTCDate();

    const returnDate = Date.UTC(year, month, day) - initialState;

    return Date.UTC(year, month, day);
    // return returnDate;
  }

  dateComparator(projectStartDate, expectedDate, completedDate) {
    const start_date = this.expectedDateFormatter(projectStartDate);
    const date1 = this.expectedDateFormatter(expectedDate);
    const date2 = this.expectedDateFormatter(completedDate);
    // console.log(date1, date2);
    if (date2 < date1) {
      // console.log(date2 - start_date);
      return date2;
    } else if (date2 >= date1) {
      // console.log(date1 - start_date);
      return date1;
    }
  }

  runningDateFormatter(startDate, endDate) {
    const startingDate = this.expectedDateFormatter(startDate);
    const endingDate = this.expectedDateFormatter(endDate);
    const difference = endingDate - startingDate;
    const days = difference / (1000 * 3600 * 24);
    Date.prototype["addDays"] = function (days) {
      let date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    // console.log("days", days);
    let date = new Date(startDate);
    // console.log(date["addDays"](days));
    const newDate = date["addDays"](days);
    const date1 = this.expectedDateFormatter(newDate);
    const date2 = this.expectedDateFormatter(startDate);
    const timeDifference = date1 - date2;
    // console.log(date1, date2);
    // console.log(timeDifference);

    return timeDifference;
  }

  ngAfterContentInit() { }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.toLocaleLowerCase();
  };

  downloadReport() {
    this.reportsService.getAllProjectsReport().subscribe((res) => {
      this.isDownloading = true;
      // console.log(res);
      this.reportsService.checkDownloadStatus(res.id).subscribe((res) => {
        this.isDownloading = false;
        this.isDownloaded = res;
        // console.log(res);
      });
    });
  }

  ngOnDestroy() { }
}
