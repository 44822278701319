import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  serverUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {}

  createUser(data) {
    return this.http.post<any>(
      `https://${this.serverUrl}/api/account/signup`,
      data
    );
  }

  getAllUsers() {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/account/all/users`
    );
  }

  // getAllUsers() {
  //   const url: string = `https://${this.serverUrl}/api/account/all/users`;
  //   return this.http.get<any>(url).toPromise();
  // }

  getUserProfile(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/account/profile/retrieve/${id}`
    );
  }

  deleteUser(id) {
    return this.http.delete<any>(
      `https://${this.serverUrl}/api/account/profile/delete/${id}`
    );
  }

  updateUserProfile(id, data) {
    return this.http.put<any>(
      `https://${this.serverUrl}/api/account/profile/retrieve/${id}`,
      data
    );
  }

  // update user password

  updatepassword(data) {
    return this.http.post<any>(
      `https://${this.serverUrl}/api/account/password-change`,
      data
    );
  }

  //////////// UPDATE PROJECT INFO APIS ////////////////////////////////

  getProjectList() {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/project-details/list`
    );
  }

  retrieveProjectData(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/single/project/details/${id}`
    );
  }
  retrieveStateData(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/statedata/update/${id}`
    );
  }
  retrieveDistrictData(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/districtdata/update/${id}`
    );
  }
  retrieveTehsilData(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/tehsildata/update/${id}`
    );
  }
  retrieveVillageData(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/villagedata/update/${id}`
    );
  }

  updateProjectDetails(id, data) {
    return this.http.put<any>(
      `https://${this.serverUrl}/api/project/project-details/update/${id}`,
      data
    );
  }
  updateStateDetails(id, data) {
    return this.http.put<any>(
      `https://${this.serverUrl}/api/project/statedata/update/${id}`,
      data
    );
  }
  updateDistrictDetails(id, data) {
    return this.http.put<any>(
      `https://${this.serverUrl}/api/project/districtdata/update/${id}`,
      data
    );
  }
  updateTehsilDetails(id, data) {
    return this.http.put<any>(
      `https://${this.serverUrl}/api/project/tehsildata/update/${id}`,
      data
    );
  }
  updateVillageDetails(id, data) {
    return this.http.put<any>(
      `https://${this.serverUrl}/api/project/villagedata/update/${id}`,
      data
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////
  getStateData(pid) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/data/state/detail/list?project_id=${pid}`
    );
  }
  getDistrictData(sdid) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/data/district/detail/list?statedata_id=${sdid}`
    );
  }
  getTehsilData(ddid) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/data/tehsil/detail/list?districtdata_id=${ddid}`
    );
  }
  getVillageData(tdid) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/data/village/detail/list?tehsildata_id=${tdid}`
    );
  }
  //////////////////////// LIST Services ////////////////////////
  getStateList() {
    return this.http.get<any>(`https://${this.serverUrl}/api/data/state/list`);
  }
  getDistrictList(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/data/district/list/${id}`
    );
  }

  getTehsilList(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/data/tehsil/list/${id}`
    );
  }

  //  Get village by tehsil name
  getVillageList(id) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/data/village/list/${id}`
    );
  }

  createVillage(data) {
    return this.http.post<any>(
      `https://${this.serverUrl}/api/data/village`,
      data,
      { responseType: "json", observe: "response" }
    );
  }
  createTehsil(data) {
    return this.http.post<any>(
      `https://${this.serverUrl}/api/data/tehsil`,
      data
    );
  }
  createDistrict(data) {
    return this.http.post<any>(
      `https://${this.serverUrl}/api/data/district`,
      data
    );
  }
  createState(data) {
    return this.http.post<any>(
      `https://${this.serverUrl}/api/data/state`,
      data
    );
  }

  ////////////////////// CREATE USERS LIST /////////////////////////////
  createPrincipalSec(data) {
    return this.http.post<any>(
      `https://${this.serverUrl}/api/project/principal`,
      data
    );
  }
  createComissioner(data) {
    return this.http.post<any>(
      `https://${this.serverUrl}/api/data/comissioner`,
      data
    );
  }
  createDistrictCollector(data) {
    return this.http.post<any>(
      `https://${this.serverUrl}/api/data/district-collector`,
      data
    );
  }
  createExecutiveEngg(data) {
    return this.http.post<any>(
      `https://${this.serverUrl}/api/project/executive-engineer`,
      data
    );
  }
  createCALA(data) {
    return this.http.post<any>(`https://${this.serverUrl}/api/data/cala`, data);
  }
  createRegionalOfficer(data) {
    return this.http.post<any>(
      `https://${this.serverUrl}/api/project/regional-officer`,
      data
    );
  }
  createTILR(data) {
    return this.http.post<any>(`https://${this.serverUrl}/api/data/tilr`, data);
  }

  updateUserDesignation(data) {
    return this.http.post(
      `https://${this.serverUrl}/api/account/update/user/add/remove`,
      data
    );
  }

  ///////////////// Change Deadlines ////////////////////////////////
  getCurrentProjectDeadlines() {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/process-days/update`
    );
  }

  updateNewDeadlines(data) {
    return this.http.put<any>(
      `https://${this.serverUrl}/api/project/process-days/update`,
      data
    );
  }

  // ///////////// Delete Village ////////////////////////////

  villagelist(village) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/data/search-village?search=${village}`
    );
  }

  deletevillage(village_id) {
    // return new Observable<any>(observe=>{
    //   this.http.delete<any>( `https://${this.serverUrl}/api/data/delete-village?id=${village_id}`).subscribe((response)=>{
    //     observe.next(response)

    //   },(error)=>{
    //     console.log(error,"eroror")
    //     observe.next(error)

    //   })
    // });
    return this.http.delete<any>(
      `https://${this.serverUrl}/api/data/delete-village?id=${village_id}`,
      { responseType: "json", observe: "response" }
    );
  }

  // get project details and pn date

  getpnDate(villid) {
    return this.http.get<any>(
      `https://${this.serverUrl}/api/project/project-details/retrieve/${villid}`
    );
  }
}
