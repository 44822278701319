import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { ProjectService } from "../../../services/project.service";
import { NotificationService } from "../../../services/notification.service";
import { AdminService } from "../../../services/admin.service";
import { emailValidator } from "../../../shared/custom-validator/email.validator";
import { Subscription } from "rxjs";
// import { group } from "console";

interface DialogData {
  email: string;
}

@Component({
  selector: "app-add-user",
  templateUrl: "./addUser.component.html",
  styleUrls: ["./addUser.component.css"],
})
export class ModalAddUserComponent implements OnInit {
  completionForm_possession: FormGroup;
  id: any;

  villageData: any;
  showWarning: boolean = false;
  remainingLand: any;
  emailList = [];
  userForm: FormGroup;
  userListSubs: Subscription;
  constructor(
    public dialogRef: MatDialogRef<ModalAddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private notification: NotificationService,
    private adminService: AdminService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.projectService.villageID.subscribe((res) => {
      this.id = res;
    });
    this.userListSubs = this.adminService.getAllUsers().subscribe((res) => {
        console.log(res);
        // For email validation
        for (let index = 0; index < res.length; index++) {
          this.emailList.push(res[index].email);
        }
      });
    this.userForm = this.fb.group({
      first_name: [null, [Validators.required]],
      middle_name: [null],
      last_name: [null, [Validators.required]],
      email: [null, [Validators.required, emailValidator(this.emailList)]],
      age: [null, [Validators.required]],
      gender: [null, [Validators.required]],
      office_no: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(12),
          Validators.minLength(10),
          Validators.pattern("^[0-9]*$"),
          // officeNoValidator(this.officeNo)
        ]),
      ],
      mobile: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(12),
          Validators.minLength(10),
          Validators.pattern("^[0-9]*$"),
          // mobileNoValidator(this.mobileNo)
        ]),
      ],
      // telephone: [null,[Validators.required,telephoneNoValidator(this.telePhoneNo)]],
      telephone: [null],
      address: [null, [Validators.required]],
      role: [null, [Validators.required]],
      designation: [null, [Validators.required]],
      password: [null],
    });
  }

  postUserDataToSuitableTable(userData) {
    const designation = userData.designation;
    if (designation === "Principal secretary") {
      const userInfo = {
        principal: userData.userId,
      };
      this.adminService.createPrincipalSec(userInfo).subscribe((res) => {
        console.log(res);
        localStorage.setItem('principal_id',res.id)

      });
    } else if (designation === "Comissioner") {
      const comm = {
        comissioner: userData.userId,
      };
      this.adminService.createComissioner(comm).subscribe((res) => {
        console.log(res);
        localStorage.setItem('comissioner_id',res.id)
      });
    } else if (designation === "District collector") {
      const userInfo = {
        district_collector: userData.userId,
      };
      this.adminService.createDistrictCollector(userInfo).subscribe((res) => {
        console.log(res);
        localStorage.setItem('district_collector_id',res.id)
      });
    } else if (designation === "Cala") {
      const userInfo = {
        cala: userData.userId,
      };
      this.adminService.createCALA(userInfo).subscribe((res) => {
        console.log(res);
        localStorage.setItem('cala_id',res.id)
      });
    } else if (designation === "Executive engineer") {
      const userInfo = {
        executive_engineer: userData.userId,
      };
      this.adminService.createExecutiveEngg(userInfo).subscribe((res) => {
        console.log(res);
        localStorage.setItem('executive_engineer_id',res.id)
      });
    } else if (designation === "Regional officer") {
      const userInfo = {
        regional_officer: userData.userId,
      };
      this.adminService.createRegionalOfficer(userInfo).subscribe((res) => {
        console.log(res);
        localStorage.setItem('ro_id',res.id)
      });
    } else if (designation === "TILR") {
      const userInfo = {
        tilr: userData.userId,
      };
      this.adminService.createTILR(userInfo).subscribe((res) => {
        console.log(res);
        localStorage.setItem('tilr_id',res.id)
      });
    }
  }

  onSubmitUserForm() {
    const data = this.userForm.value;
    console.log(data, "user form data");

    this.adminService.createUser(data).subscribe(
      (res) => {
        console.log(res);
        let userData = {
          designation: res.designation,
          userId: res.id,
        };
        console.log(userData);
        this.postUserDataToSuitableTable(userData);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Detail posted successfully!!!"
        );
        this.dialogRef.close();
      },
      (err) => {
        console.error(err);
        this.notification.showNotification(
          "top",
          "right",
          "error",
          "User with this email address already exists!"
        );
      }
    );
  }
}
