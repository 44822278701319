import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgApexchartsModule } from "ng-apexcharts";

import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";

import { AppComponent } from "./app.component";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { LoginComponent } from "./user-auth/login/login.component";
import { SignUpComponent } from "./user-auth/sign-up/sign-up.component";
import { CreateProjectComponent } from "./project-forms/create-project/create-project.component";
import { ProjectProgressComponent } from "./project-forms/project-progress/project-progress.component";
import { AuthTokenInterceptors } from "./services/auth.token.interceptors";
import { VillageDetailsComponent } from "./data-sheets/village-details/village-details.component";
import { CalaListComponent } from "./data-sheets/cala-list/cala-list.component";
import { AddDataComponent } from "./add-data/add-data.component";
import { CalaFundManagementComponent } from "./data-sheets/cala-fund-management/cala-fund-management.component";
import { ProjectDetailsComponent } from "./data-sheets/project-details/project-details.component";
import { CalaDetailsComponent } from "./data-sheets/cala-details/cala-details.component";
import { MaterialModule } from "./angular-material.module";
import { FilterProjectComponent } from "./project-forms/filter-project/filter-project.component";
import { CreateUserComponent } from "./users/create-user/create-user.component";
import { StepperFormComponent } from "./project-forms/stepper-form/stepper-form.component";
import { EditProjectComponent } from "./project-forms/edit-project/edit-project.component";
import { ModalComponent } from "./project-forms/project-progress/modal/modal.component";
import { Modal3DComponent } from "./project-forms/project-progress/modal/modal3D.component";
import { Modal3GComponent } from "./project-forms/project-progress/modal/modal3G.component";
import { Modal3HComponent } from "./project-forms/project-progress/modal/modal3H.component";
import { ModalCalaFundComponent } from "./project-forms/project-progress/modal/CalaFund.modal.component";
import { ModalPossessionComponent } from "./project-forms/project-progress/modal/possession.modal.component";
import { ModalMutationComponent } from "./project-forms/project-progress/modal/mutation.modal.component";
import { ModalJMSComponent } from "./project-forms/project-progress/modal/modal.jms.component";
import { SpinnerComponent } from "./shared/spinner/spinner.component";
import { LoaderInterceptor } from "./services/loader.interceptor";
import { ProjectDashboardComponent } from "./project-dashboard/projects/project-dashboard.component";
import { DistrictProgressComponent } from "./project-forms/district-progress/district-progress.component";
import { ProjectInfoComponent } from "./project-dashboard/project-info/project-info.component";
import { ReportsComponent } from "./reports/reports.component";
import { ProjectComponent } from "./dashboard/project-dashboard/project-dashboard.component";
import { CalaDasboardComponent } from "./dashboard/cala-dasboard/cala-dasboard.component";
import { CollectorDashboardComponent } from "./dashboard/collector-dashboard/collector-dashboard.component";
import { CommissionerDashboardComponent } from "./dashboard/commissioner-dashboard/commissioner-dashboard.component";
import { ProjectsGraphComponent } from "./dashboard/cala-dasboard/projects-graph/projects-graph.component";
import { CriticalAnalysisReportsComponent } from "./dashboard/critical-analysis-reports/critical-analysis-reports.component";
import { DateDialogComponent } from "./dashboard/critical-analysis-reports/date-dialog/date-dialog.component";
import { ChangeDeadlinesComponent } from "./change-deadlines/change-deadlines.component";
import { ModalGovtLandPossessionComponent } from "./project-forms/project-progress/modal/govtLandPossession.modal.component";
import { ModalGovtLandMutation } from "./project-forms/project-progress/modal/govtLandMutation.modal.component";
import { ProjectListByRoComponent } from "./dashboard/critical-analysis-reports/project-list-by-Ro/project-list-by-ro/project-list-by-ro.component";

import { ProjectReportsComponent } from "./reports/project-reports/project-reports.component";
import { CalaReportComponent } from './reports/dashboard-reports/cala-report/cala-report.component';
import { DashboardReportComponent } from "./reports/dashboard-reports/dashboard-report/dashboard-report.component";
import { CollectorReportComponent } from "./reports/dashboard-reports/collector-reports/collector-reports.component";
import { CommissionerReportComponent } from "./reports/dashboard-reports/Commissioner-reports/commissioner-reports.component";
import { CriticalReportComponent } from "./reports/dashboard-reports/critical-report/criticle-report.component";
import { SecretaryComponent } from "./dashboard/secretary-dashboard/secretary-dashboard.component";
import { DeleteVillageComponent } from "./delete-villlage/dalete-village.component";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ProjectListComponent } from "./dashboard/project-list/project-list.component";
import { PrivateDashboardComponent } from "./dashboard/private-dashboard/private-dashboard.component";
import { PrivateNegotiationComponent } from "./project-forms/private-negotiation/private-negotiation.component";
import { TotallandAcquistionModelComponent } from "./project-forms/private-negotiation/modal/totalLandacquistion.component";
import { ProjectListInfoComponent } from "./dashboard/project-info/project-info.component";
import { CalainfoListComponent } from "./dashboard/cala-list/cala-list.component";
import { ActivityComponent } from "./activity/activity.component";
import {NgxPaginationModule} from 'ngx-pagination';
import { VerticleFormComponent } from "./project-forms/verticle-form/verticle-form.component";
import { NgSelectModule } from '@ng-select/ng-select';
import { Modal3CComponent } from "./project-forms/project-progress/modal/modal3C.component";
import { ModalAddUserComponent } from "./project-forms/verticle-form/modalAddUser/addUser.component";



@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    CommonModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    NgApexchartsModule,
    AppRoutingModule,
    NgbModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxPaginationModule,
    NgSelectModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    SignUpComponent,
    CreateProjectComponent,
    CreateUserComponent,
    ProjectProgressComponent,
    VillageDetailsComponent,
    CalaListComponent,
    AddDataComponent,
    ProjectDetailsComponent,
    CalaDetailsComponent,
    CalaFundManagementComponent,
    FilterProjectComponent,
    StepperFormComponent,
    EditProjectComponent,
    ModalComponent,
    Modal3DComponent,
    Modal3GComponent,
    Modal3HComponent,
    ModalCalaFundComponent,
    ModalPossessionComponent,
    ModalMutationComponent,
    ModalJMSComponent,
    SpinnerComponent,
    ProjectDashboardComponent,
    DistrictProgressComponent,
    ProjectInfoComponent,
    ReportsComponent,
    ProjectComponent,
    CalaDasboardComponent,
    CollectorDashboardComponent,
    CommissionerDashboardComponent,
    ProjectsGraphComponent,
    CriticalAnalysisReportsComponent,
    DateDialogComponent,
    ChangeDeadlinesComponent,
    ModalGovtLandPossessionComponent,
    ModalGovtLandMutation,
    ProjectListByRoComponent,
    ProjectReportsComponent,
    CalaReportComponent,
    DashboardReportComponent,
    CollectorReportComponent,
    CommissionerReportComponent,
    CriticalReportComponent,
    SecretaryComponent,
    DeleteVillageComponent,
    ProjectListComponent,
    PrivateDashboardComponent,
    PrivateNegotiationComponent,
    TotallandAcquistionModelComponent,
    ProjectListInfoComponent,
    CalainfoListComponent,
    ActivityComponent,
    VerticleFormComponent,
    Modal3CComponent,
    ModalAddUserComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptors,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
